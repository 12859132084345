var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.ranks),function({ position, points }){return _c('LeaderboardRanking',{key:position,staticClass:"mt-1",attrs:{"userRankings":_vm.rankedUsers.filter(user => user.rank === position),"position":position,"link-predictions":true,"points":points}})}),(!_vm.isCurrentUserRanked)?_c('LeaderboardRanking',{key:-1,staticClass:"mt-1",attrs:{"userRankings":[
      {
        id: _vm.currentUser.id,
        name: _vm.currentUser.name,
        userId: _vm.currentUser.id,
        photoKey: _vm.currentUser.photoKey || _vm.currentUser.photo_key,
        points: _vm.userRank ? _vm.userRank.points : '-',
        position: _vm.userRank ? _vm.userRank.rank : null,
      },
    ],"points":_vm.userRank ? _vm.userRank.points : '-',"position":_vm.userRank ? _vm.userRank.rank : null,"padding-start":true,"link-predictions":false}}):_vm._e(),_c('LeaderboardActions',{attrs:{"leaderboard":_vm.leaderboard}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }